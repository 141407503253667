import { APPS, MENUS } from "./config.platform"
import Env from "@/util/env"
export const ALL_APPS = {
  info: {
    name: "info",
    entry: process.env.REACT_APP_INFO,
    activeRule: "/",
  },
  passport: {
    name: "passport",
    entry: process.env.REACT_APP_PASSPORT,
    activeRule: "/passport/",
  },
  bi: {
    name: "bi",
    entry: process.env.REACT_APP_ZhiShu,
    activeRule: "/bi/",
  },
  dmp: {
    name: "dmp",
    entry: process.env.REACT_APP_ZhiXiang,
    activeRule: "/dmp/",
  },
  lbs: {
    name: "lbs",
    entry: process.env.REACT_APP_ZhiXuan,
    activeRule: "/lbs/",
  },
  bill: {
    name: "bill",
    entry: process.env.REACT_APP_BILL,
    activeRule: "/bill/",
  },
  zhiguan: {
    name: "zhiguan",
    entry: process.env.REACT_APP_ZhiGuan,
    activeRule: "/zhiguan/",
  },
  // zhiguan_travel: {
  //   name: "travel",
  //   entry: process.env.REACT_APP_ZhiGuan,
  //   activeRule: "/travel/",
  // },
  merchant: {
    name: "merchant",
    entry: process.env.REACT_APP_Merchant,
    activeRule: "/merchant/",
  },
}
export type TPageConfig = {
  name: string
  link: string
  id: string
  icon: string
  icon_grey: string
  linkBuy?: string
}
interface IMenuMap {
  dashboard: TPageConfig
  zhixuan: TPageConfig
  zhishu: TPageConfig
  jingzhun: TPageConfig
  zhiguan: TPageConfig
  ncov: TPageConfig
  merchant: TPageConfig
  zhiguan_travel: TPageConfig
}
export const ALL_MENUS: IMenuMap = {
  dashboard: {
    name: "DASHBOARD",
    link: "/dashboard/#/",
    id: "1",
    icon: require("@assets/dashboard_h.png"),
    icon_grey: require("@assets/dashboard.png"),
  },
  zhixuan: {
    name: "智选",
    link: "/lbs/#/",
    id: "2",
    icon: require("@assets/zhixuan_h.png"),
    icon_grey: require("@assets/zhixuan.png"),
    linkBuy: "/bill/#/purchase/zhixuan",
  },
  zhishu: {
    name: "智数",
    link: "/bi/#/",
    id: "3",
    icon: require("@assets/zhishu_h.png"),
    icon_grey: require("@assets/zhishu.png"),
  },
  jingzhun: {
    name: "精准营销",
    link: "/dmp/#/",
    id: "4",
    icon: require("@assets/jingzhun_h.png"),
    icon_grey: require("@assets/jingzhun.png"),
    linkBuy: "/bill/#/purchase/dmp",
  },
  zhiguan: {
    name: "智观",
    link: "/zhiguan/#/",
    id: "5",
    icon: require("@assets/zhiguan_h.png"),
    icon_grey: require("@assets/zhiguan.png"),
  },
  zhiguan_travel: {
    name: "智观",
    link: "/zhiguan/#/travel",
    id: "51",
    icon: require("@assets/zhiguan_h.png"),
    icon_grey: require("@assets/zhiguan.png"),
  },
  ncov: {
    name: "疫情 AI 分析",
    link: "/bi/#/ncov",
    id: "6",
    icon: require("@assets/ncov_h.png"),
    icon_grey: require("@assets/ncov.png"),
  },
  merchant: {
    name: "商户管理",
    link: "/merchant/#/",
    id: "7",
    icon: require("@assets/merchant_h.png"),
    icon_grey: require("@assets/merchant.png"),
  },
}

export const PRODUCTS = {
  0: ALL_MENUS.zhixuan,
  1: ALL_MENUS.zhiguan,
  2: ALL_MENUS.zhishu,
  3: ALL_MENUS.jingzhun,
}

// 旅游大屏演示配置
export const TRAVEL_CONFIG = {
  apps: [
    ALL_APPS.info,
    ALL_APPS.passport,
    ALL_APPS.bill,
    ALL_APPS.lbs,
    ALL_APPS.zhiguan,
  ],
  menus: [ALL_MENUS.dashboard, ALL_MENUS.zhixuan, ALL_MENUS.zhiguan_travel],
}

const TRAVEL_USER = ["15721246064", "13120606681"]
// 获取微前端app
export const getApps = () => {
  let userName = Env.getUser().user_name
  if (userName && TRAVEL_USER.includes(userName)) {
    return TRAVEL_CONFIG.apps
  } else {
    return APPS.map((cf) => {
      return ALL_APPS[cf] || cf
    })
  }
}

/**
 * 获取当前菜单
 * @param userName
 * @returns
 */
export const getMenus = () => {
  let userName = Env.getUser().user_name
  if (userName && TRAVEL_USER.includes(userName)) {
    return TRAVEL_CONFIG.menus
  } else {
    return MENUS.map((cf) => {
      return ALL_MENUS[cf] || cf
    })
  }
}
