import { API_BFF, API_CLOUD } from "@constants/config"

import Http from "@/util/http"

export const getProducts = () => {
  return Http.get(API_BFF + `/v1/products`)
}
//获取用户信息
export const getUserInformation = (username) => {
  try {
    return Http.get(API_CLOUD + `/v1/customers/userName/${username}`)
  } catch (error) {
    console.log("error", error)
  }
}
