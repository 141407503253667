import React, { FC, useMemo } from "react"
import ProductsOpened from "./component/ProductsOpened"
import ProductDetails from "./component/ProductDetails"
import useUserModel from "@models/useUserModel"
import { useRequest } from "ahooks"
import { getProducts, getUserInformation } from "@api/dashboard"
import { TProduct, TProductCode, UserType } from "@api/interface"
import { useHistory } from "react-router-dom"
import "./index.scss"

const ConventionalProducts: TProductCode[] = [3, 0, 1]
const DashBoard: FC = () => {
  const { data } = useRequest<TProduct[] | undefined>(getProducts)
  const useUser = useUserModel()
  const userName = useUser.userInfo.user_name
  const userData = useRequest<UserType>(() => getUserInformation(userName), {
    refreshDeps: [userName],
  })
  const balance = userData.data && userData.data.balance
  const balanceList = useMemo(() => {
    const list = [
      { count: `${balance ? balance : 0.0}`, name: "余额(元)" },
      { count: "0.0", name: "代金券(元)" },
      { count: "0.0", name: "可开票金额(元)" },
    ]
    return list
  }, [balance])
  const history = useHistory()

  const productMap = useMemo(() => {
    let mp = {}
    data &&
      data.forEach((pro) => {
        mp[pro.code] = pro
      })
    return mp
  }, [data])
  return (
    <div className="dashboard-main">
      {/* <div className="title">欢迎回来, {userName}。</div> */}
      <div className="container flex">
        <div className="left-div flex flex-column">
          <ProductsOpened data={data} />
          <div className="conventional-div flex">
            {ConventionalProducts.map((code) => (
              <ProductDetails
                key={code}
                code={code}
                product={productMap[code]}
              />
            ))}
          </div>
        </div>
        <div className="right-div flex flex-column">
          <div className="userCard flex flex-column">
            <div className="top-div flex">
              <div className="img-user flex flex-1">
                <img
                  src={require("@assets/userLogo.png")}
                  alt=""
                  style={{ width: 50 }}
                />
                <div className="userName flex flex-column">
                  <span>{userName}</span>
                  <span>手机号: {userName}</span>
                </div>
              </div>
              <div className="personal-center">
                <span>个人中心</span>
              </div>
              <div
                className="finance-center"
                onClick={() => history.push("/bill/#/")}
              >
                <span>财务中心</span>
              </div>
            </div>
            <div className="line-div"></div>
            <div className="bottom-div flex ">
              {balanceList.map((item, index) => {
                return (
                  <div className="cell-div flex flex-column" key={index}>
                    <span>￥{item.count}</span>
                    <span>{item.name}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="weChat-div flex">
            <div className="describe-div flex flex-column">
              <div className="describe-top-div flex">
                <img src={require("@assets/wechat.png")} alt="" />
                <span>微信扫码关注</span>
              </div>
              <div className="describe-bottom-div flex flex-column">
                <p>维智重磅推出，掌上选址工具</p>
                <p>欢迎扫码关注汇客通</p>
              </div>
            </div>
            <div className="weChat-Logo">
              <img
                src={require("@assets/huiketong.png")}
                alt=""
                style={{ width: 126 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashBoard
