/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios"
import { message } from "antd"
import Env from "@util/env"

/**
 ** 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = (msg: string) => {
  message.warn(msg)
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  setTimeout(() => {
    Env.login()
  }, 2000)
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status: number, message?: string) => {
  // 状态码判断
  if (status === 403) {
    toLogin()
  }
  tip(message || "未知错误")
}

// 创建axios实例
const instance = axios.create({})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  // 在发送请求之前做些什么
  (config) => {
    const token = Env.getUser()["potoken"]
    token &&
      (config.headers.token = token) &&
      (config.headers.Authorization = token)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    return Promise.resolve(res.data)
  },
  // 请求已发出，但是不在2xx的范围
  (error) => {
    const { response } = error
    console.error("error", error, response)
    // 对响应错误做点什么
    if (response) {
      const { status, data } = response
      errorHandle(status, (data && data.message) || error.toString())
      return Promise.reject(error)
    }
  }
)

export default instance
