import React, { Suspense } from "react"
import Header from "@components/Header"
import LeftMenu from "@components/LeftMenu"
import DashBoard from "@components/DashBoard"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom"
import docCookies from "./util/cookies"
import style from "./app.module.css"
const user = docCookies.parsedCookies()
const locations = window.location.href
const LayoutEmpty = () => {
  if (window.location.hash === "#/accesskey") {
    return (
      <div className={style.App}>
        <Header />
        <div className={style.main}>
          <div className={style.appWraper} id="subapp-viewport"></div>
        </div>
      </div>
    )
  }
  return <div className={style.appWraperMain} id="subapp-viewport"></div>
}
const LayoutDefault = () => {
  let { pathname } = useLocation()
  return (
    <div className={style.App}>
      <Header />
      <div className={style.main}>
        {pathname !== "/bill/" ? <LeftMenu /> : null}
        <div className={style.appWraper} id="subapp-viewport"></div>
      </div>
      <Footer />
    </div>
  )
}
const Dashboard = () => {
  return (
    <div className={style.App}>
      <Header />
      <div className={style.main}>
        <LeftMenu />
        <div className={style.appWraper} style={{ padding: 20, fontSize: 16 }}>
          <DashBoard />
        </div>
      </div>
      <Footer />
    </div>
  )
}


const Footer = () => {
  return (
    <div className={style.footer}>
      <span className={style.footer_span}>上次登录时间：{user.login_time}</span>
      <span className={style.footer_span}>上次登录地址：{user.login_ip}</span>
    </div>
  )
}
const renderRouter = (component) => {
  if (user.potoken) {
    return component
  } else {
    return (
      <Redirect
        to={{
          pathname: `/passport/#/login?redirect=${encodeURIComponent(
            locations
          )}`,
        }}
      />
    )
  }
}
function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={LayoutEmpty}></Route>
          <Route
            exact
            path="/dashboard"
            render={() => renderRouter(<Dashboard />)}
          ></Route>
          <Route exact path="/passport" component={LayoutEmpty}></Route>
          <Route
            exact
            path={[
              "/lbs",
              "/bi",
              "/zhiguan",
              "/dmp",
              "/bill",
              "/passport",
              "/merchant",
            ]}
            render={() => renderRouter(<LayoutDefault />)}
          ></Route>
          <Route exact path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
