import React, { FC } from "react"
import { useHistory } from "react-router-dom"
import { PRODUCTS } from "@constants/config.app"
import { TProduct } from "@api/interface"
import "./index.scss"

type ProductsOpenedPropsType = {
  data: TProduct[] | undefined
}
const ProductsOpened: FC<ProductsOpenedPropsType> = ({ data }) => {
  const history = useHistory()
  return (
    <div className="product-main">
      <h2 className="title-h2">已开通产品</h2>
      <ul className="product-list flex flex-align-center">
        {data &&
          data.map((item, key) => {
            let pro = PRODUCTS[item.code]
            if (!pro) return null
            return (
              <li
                className="product-item flex  "
                key={key}
                onClick={() => history.push(pro.link)}
              >
                <img src={pro.icon} alt="productLogo" style={{ width: 24 }} />
                <span className="name">{item.name}</span>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default ProductsOpened
