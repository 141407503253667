import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import useUserModel from "@models/useUserModel"
import { LOGO } from "@constants/config.platform"
import { getUserInformation } from "@api/dashboard"
import { Menu, Dropdown } from "antd"
import { useRequest } from "ahooks"
import { UserType } from "@api/interface"
// import Env from "@util/env"
import "./index.scss"

import useEffectOnce from "@/reactUse/useEffectOnce"
const menu = (
  <Menu theme="dark">
    {/* 暂时隐藏
    <Menu.Item>
      <a rel="noopener noreferrer" href="/dashboard/#">
        基本资料
      </a>
    </Menu.Item>
    <Menu.Item>
      <a rel="noopener noreferrer" href="/dashboard/#">
        子账户管理
      </a>
    </Menu.Item> */}
    <Menu.Item>
      <a rel="noopener noreferrer" href="/passport/#/accesskey">
        AccessKey 管理
      </a>
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        sessionStorage.removeItem("logoPath")
        window.location.href = "/passport/#/logout"
      }}
    >
      退出登录
    </Menu.Item>
  </Menu>
)
const menu1 = [
  {
    name: "控制台",
    level: 1,
    link: window.location.origin + "/dashboard/#",
  },
  {
    id: 6,
    name: "费用",
    level: 1,
    children: [
      {
        id: 601,
        name: "充值",
        link: window.location.origin + "/bill/#/recharge",
      },
      { id: 602, name: "订单", link: window.location.origin + "/bill/#/order" },
      {
        id: 603,
        name: "消费明细",
        link: window.location.origin + "/bill/#/transactions",
      },
    ],
  },
]

function getMeau(defaultSelect, setDefaultSelect, menu = []) {
  if (menu.length === 0) {
    return null
  }
  return (
    <div className="menu flex flex-align-center">
      {menu.map((item) => {
        const flag = item.children && item.children.length !== 0
        return (
          <div
            className="nav-title flex flex-align-center"
            key={item.name}
            onClick={(e) => {
              e.nativeEvent.stopImmediatePropagation()
              if (defaultSelect) {
                setDefaultSelect(null)
                return
              }
              setDefaultSelect(item.id)
            }}
          >
            <div className=" flex flex-center">
              {flag ? (
                <>
                  <span className="name">{item.name}</span>
                  <img
                    src={require("@assets/down_arrow.png")}
                    width="10"
                    height="6"
                    className="arrow"
                    alt=""
                  />
                </>
              ) : item.path ? (
                <Link to={item.path}>
                  <span className="name">{item.name}</span>
                </Link>
              ) : item.link ? (
                <a href={item.link}>
                  <span className="name">{item.name}</span>
                </a>
              ) : (
                <span className="name">{item.name}</span>
              )}
            </div>

            {flag ? (
              <ul
                className={`item-list flex flex-column`}
                style={{
                  display: defaultSelect === item.id ? "block" : "none",
                }}
              >
                {item.children.map((c) => {
                  return c.path ? (
                    <Link to={c.path} key={c.id}>
                      <li className="link" key={c.id}>
                        {c.name}
                      </li>
                    </Link>
                  ) : c.link ? (
                    <a href={c.link} key={c.id}>
                      <li className="link">{c.name}</li>
                    </a>
                  ) : (
                    <li className="link" key={c.id}>
                      {c.name}
                    </li>
                  )
                })}
              </ul>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

function ButtonWrapper() {
  return (
    <div className="button-wrapper flex flex-align-center">
      <Link to="/passport/#/login">
        <span className="login-btn">登录</span>
      </Link>
      <Link to="/passport/#/login">
        <span className="register-btn">注册</span>
      </Link>
    </div>
  )
}

function UserBar({ user_name, userSettingVisiable, setUserSettingVisiable }) {
  return (
    <div className="user-wrapper flex flex-align-center">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayClassName="portal-menu"
      >
        <div
          className="user flex flex-align-center"
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation()
          }}
        >
          <img
            src={require("@assets/default_avatar_icon.png")}
            width="26"
            height="26"
            alt=""
            className="avatar"
          />

          <span className="user_name">{user_name}</span>
          <img
            src={require("@assets/down_arrow.png")}
            width="10"
            height="6"
            className="arrow"
            alt=""
          />
          {userSettingVisiable ? (
            <div className="setting-wrapper flex flex-center"></div>
          ) : null}
        </div>
      </Dropdown>
    </div>
  )
}

function Header({ className = "" }) {
  const { userInfo } = useUserModel()

  const userName = userInfo.user_name
  const [defaultSelect, setDefaultSelect] = useState(null)
  const [userSettingVisiable, setUserSettingVisiable] = useState(false)
  const [logoPath, setLogoPath] = useState(null)
  const logoPathCookie = sessionStorage.getItem("logoPath")
  const { run } = useRequest<UserType>(getUserInformation, {
    manual: true,
    onSuccess: (result) => {
      if (result) {
        let { channelLogo } = result
        if (channelLogo) {
          setLogoPath(channelLogo)
          sessionStorage.setItem("logoPath", channelLogo)
        }
      }
    },
  })

  useEffect(() => {
    const listern = () => {
      setDefaultSelect(null)
      setUserSettingVisiable(false)
    }
    document.addEventListener("click", listern, false)
    return () => document.removeEventListener("click", listern, false)
  }, [])

  useEffectOnce(() => {
    if (logoPathCookie) {
      return
    }
    run(userName)
  })

  const renderLogo = useMemo(() => {
    if (logoPathCookie || logoPath) {
      return (
        <div style={{ textAlign: "left" }}>
          <img
            src={logoPathCookie || logoPath}
            width="130"
            height="31.5"
            className="logo"
            alt="logo"
          />
          <p>POWERED BY WAYZ.AI</p>
        </div>
      )
    } else {
      return LOGO
    }
  }, [logoPath, logoPathCookie])

  return (
    <div className={`portal-app-header`}>
      {renderLogo}
      <div className="center flex-1 flex">
        <div className="right">
          {getMeau(defaultSelect, setDefaultSelect, menu1)}
        </div>
      </div>
      <div className="right">
        {userInfo.potoken ? (
          <UserBar
            user_name={userInfo.user_name}
            userSettingVisiable={userSettingVisiable}
            setUserSettingVisiable={setUserSettingVisiable}
          />
        ) : (
          <ButtonWrapper />
        )}
      </div>
    </div>
  )
}

export default Header
