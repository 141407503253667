const location = window.location
export const COOKIE_DOMAIN = location.hostname.replace(
  /^.*((\.[\w-]+){2})$/,
  "$1"
)
export const FN_CALLBACK_LOGIN_NAME = "__LOGIN_CALLBACK__"
export const LOGO_API = process.env.REACT_APP_LOGO_API
export const BILL_URL = process.env.REACT_APP_BILL
export const API_BFF = process.env.REACT_APP_API_BFF
export const API_CLOUD = process.env.REACT_APP_API_CLOUD

export const URL_PASSPORT = window.location.origin + "/passport/#"