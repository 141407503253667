import React, { FC, useCallback, useMemo, useEffect, useState } from "react"
import { DEFAULT_PAGE_TITLE } from "@constants/config.platform"
import { getMenus } from "@constants/config.app"
import style from "./index.module.scss"
import { useLocation } from "react-router-dom"

const BIMenu: FC = () => {
  const location = useLocation()
  const [current, setCurrent] = useState({
    name: DEFAULT_PAGE_TITLE,
    id: "null",
  })
  const MENUS = useMemo(() => {
    return getMenus()
  }, [])
  useMemo(() => {
    let path = location.pathname + (location.hash || "#/")
    let NEWMENUS = MENUS.slice(0).reverse()
    let c = NEWMENUS.filter((m) => path.includes(m.link)).sort(
      (a, b) => b.link.length - a.link.length
    )
    c[0] && setCurrent(c[0])
  }, [location.pathname, location.hash, MENUS])

  const handleMenuClick = useCallback((item) => {
    window.location.href = item.link
    setCurrent(item) // 只有hash变化时不会触发组件
  }, [])

  //根据路由动态设置title
  useEffect(() => {
    if (current && current.name) {
      document.title = current.name
    }
  }, [current])
  return (
    <div className={style["app-left-menu"]}>
      <ul>
        {MENUS.map((item) => (
          <li
            className={`${style["level-item"]} ${item.id === current.id ? style.active : ""
              }`}
            key={item.id}
            onClick={() => handleMenuClick(item)}
          >
            <>
              <img
                className={style.img}
                src={item.id === current.id ? item.icon : item.icon_grey}
                alt={item.name}
              />
              <span className={style.title}>{item.name}</span>
            </>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BIMenu
