import React, { FC, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { PRODUCTS } from "@constants/config.app"
import { TPageConfig } from "@/constants/config.app"
import { TProduct, TProductCode } from "@api/interface"
import "./index.scss"

type ProductDetailsPropsType = {
  code: TProductCode
  product: TProduct | null
}
const quotaMap = {
  3: ["洞察报告", "营销活动", "短信营销"],
  0: ["位置洞察-基础版", "位置洞察-高级版"],
  1: ["位置城市看板", "位置网点报告"],
}

const CountLeft: FC<{ name: string; count: number }> = ({ name, count }) => {
  return (
    <tr className="quota-details">
      <td>{name}</td>
      <td>剩余可用: {count.toLocaleString()}次</td>
    </tr>
  )
}
const ProductDetails: FC<ProductDetailsPropsType> = ({ code, product }) => {
  const history = useHistory()
  const config: TPageConfig = useMemo(() => {
    return PRODUCTS[code]
  }, [code])
  if (!config) return null
  return (
    <div
      className={
        code === 1
          ? "width-100 details-main height-274"
          : "width-50 details-main"
      }
    >
      <div className="top-div">
        <div className="img-title">
          <img src={`${config.icon}`} alt="productLogo" style={{ width: 44 }} />
          <span className="name">{config.name}</span>
        </div>
        {config.linkBuy ? (
          <div className="quota" onClick={() => history.push(config.linkBuy)}>
            额度充值
          </div>
        ) : null}
      </div>
      <div className="content">
        <table>
          <tbody>
            {product
              ? product.functions.map((func, index) => (
                <CountLeft
                  name={func.name}
                  count={func.leftCount}
                  key={index}
                />
              ))
              : quotaMap[code] &&
              quotaMap[code].map((name: string, index: number) => (
                <CountLeft name={name} count={0} key={index} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ProductDetails
