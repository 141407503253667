import docCookies from "./cookies"
import { URL_PASSPORT } from "@constants/config"

var _user = null
export default {
  login() {
    window.location.href =
      URL_PASSPORT +
      "/login?redirect=" +
      encodeURIComponent(window.location.href)
  },
  readCookie() {
    const user = docCookies.parsedCookies()
    if (user && user["potoken"]) {
      _user = user
    }
  },
  getUser: function () {
    if (!_user) {
      this.readCookie()
    }
    return _user || {}
  },
}
