export interface Cookies {
  getItem: (sKey: string) => string | null
  setItem: (
    sKey: string,
    sValue: string,
    sPath?: string,
    vEnd?: Date | number,
    sDomain?: string,
    bSecure?: boolean
  ) => void
  removeItem: (sKey: string, sPath?: string, sDomain?: string) => void
  hasItem: (sKey: string) => boolean
  keys: () => any
  parsedCookies: () => any
}

const cookies: Cookies = {
  getItem: function(sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null
    )
  },
  setItem: function(sKey, sValue, sPath, vEnd, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false
    }
    let sExpires = ""
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
              : "; max-age=" + vEnd
          break
        case String:
          sExpires = "; expires=" + vEnd
          break
        case Date:
          sExpires = "; expires=" + (vEnd as Date).toUTCString()
          break
        default:
          break
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=" +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "") +
      (bSecure ? "; secure" : "")
    return true
  },
  removeItem: function(sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return false
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "")
    return true
  },
  hasItem: function(sKey) {
    return new RegExp(
      "(?:^|;\\s*)" +
        encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie)
  },
  keys: function() {
    let aKeys = document.cookie
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:=[^;]*)?;\s*/)
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
    }
    return aKeys
  },
  parsedCookies: function() {
    function reducer(accumulator: any, currentValue: string) {
      let [key, val] = currentValue.split("=")

      key = key.trim().toLowerCase()

      if (!key) {
        return accumulator
      }

      if (val) {
        val = val.trim()
      }

      accumulator[decodeURIComponent(key)] = decodeURIComponent(val)

      return accumulator
    }
    const cookie = document.cookie
    let parsed = Object.create(null) as any
    return cookie.split("; ").reduce(reducer, parsed)
  },
}

export default cookies
