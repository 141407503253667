import { useState, useCallback, useMemo } from "react"
import { createModel } from "hox"
import { FN_CALLBACK_LOGIN_NAME } from "@constants/config"
import Env from "@util/env"

export type User = {
  potoken?: string
  ktoken?: string
  userRole?: string
  user_name?: string
  user_type?: string
  oauth_uname?: string
  expires?: number | string | Date
  [propName: string]: any
}
const userKeys = [
  "potoken",
  "ktoken",
  "userRole",
  "user_name",
  "user_type",
  "oauth_name",
  "expires_in",
]

function useUser() {
  const [userInfo, setUserInfo] = useState<User>({})
  const updateUser = useCallback(
    (user) => {
      setUserInfo(user)
    },
    [setUserInfo]
  )
  useMemo(() => {
    const cookies = Env.getUser()
    let user = {}
    if (cookies) {
      userKeys.forEach((k) => {
        if (cookies[k]) {
          user[k] = cookies[k]
        }
      })
    }
    user["potoken"] && updateUser(user)

    //@ts-ignore
    window[FN_CALLBACK_LOGIN_NAME] = (user) => {
      setUserInfo(user)
    }
  }, [updateUser])
  return {
    userInfo,
  }
}

export default createModel(useUser)
