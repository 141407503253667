import React from "react"
export const DEFAULT_PAGE_TITLE = "WAYZ位置商业智能平台"
export const LOGO = (
  <span className="title">
    <a href="/" target="_blank"> WAYZ.LBI 位置商业智能平台</a>
  </span>
)
export const PRODNAME = "bi-ms"
export const STATISTIC = true

export const APPS = [
  'info',
  'passport',
  'bill',
  'lbs',
  'bi',
  'dmp',
  'zhiguan',
  'merchant',
]

export const MENUS = [
  'dashboard',
  'zhixuan',
  'zhishu',
  'merchant',
  'jingzhun',
  'zhiguan',
  'ncov',
]
