//@ts-nocheck
import React from "react"
import ReactDOM from "react-dom"
import { registerMicroApps, start, runAfterFirstMounted } from "qiankun"
import { getApps } from "@constants/config.app"
import "./index.css"
import App from "./App"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("appRoot")
)

if (module.hot) {
  module.hot.accept()
}

function genActiveRule(routerPrefix: string | string[]) {
  return (location: Location) =>
    routerPrefix === "/"
      ? location.pathname === routerPrefix
      : location.pathname.startsWith(routerPrefix)
}

// render({ loading: true })
/**
 * Step2 注册子应用
 */
const APPS = getApps()
APPS.forEach((app) => {
  app.container = "#subapp-viewport"
  app.activeRule = genActiveRule(app.activeRule)
})

registerMicroApps(APPS, {
  beforeLoad: [
    (app) => {
      // console.log("[LifeCycle] before load %c%s", "color: green;", app.name)
    },
  ],
  beforeMount: [
    (app) => {
      // console.log("[LifeCycle] before mount %c%s", "color: green;", app.name)
    },
  ],
  afterUnmount: [
    (app) => {
      // console.log("[LifeCycle] after unmount %c%s", "color: green;", app.name)
    },
  ],
})

/**
 * Step3 设置默认进入的子应用
 */
// setDefaultMountApp("/bi")

/**
 * Step4 启动应用
 */
start({
  prefetch: false,
  sandbox: false,
  singular: true,
})

runAfterFirstMounted(() => {
  // console.log("[MainApp] first app mounted")
})
